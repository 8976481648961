<template>
  <div class="container">
    <!-- <NavbarPublic /> -->
    <div class="landing-logo">
      <h2>nexy.ai</h2>
    </div>
    <div class="landing-header">
      <h1>Compartimos tu amor por los libros</h1>
      <h3>En nexy estamos creando una nueva plataforma para los amantes de la lectura</h3>
      <a href="#signup-form" class="btn-1">Apúntate</a>
    </div>
    <div class="landing-soon">
      <p>Nexy es una plataforma en construcción destinada a revolucionar la experiencia de la lectura. En nuestro
        espacio digital, los usuarios podrán crear su propia biblioteca en la nube, organizando y compartiendo tanto
        libros físicos como digitales. </p>
      <h3>Próximamente podrás...</h3>
      <div class="landing-soon-card">
        <div><i class="fa fa-book-open"></i>Crear tu biblioteca personal y compartirla</div>
        <div><i class="fa fa-robot"></i>Obtener recomendaciones personalizadas mediante IA</div>
        <div><i class="fa fa-qrcode"></i>Consultar los libros que tienes alrededor</div>


      </div>
    </div>
    <div class="landing-form" id="signup-form">
      <iframe width="540" height="600"
        src="https://6b232408.sibforms.com/serve/MUIFAHd5eENe_UYspMxhoZhWBIQcEPvMvUbmWlf7QKjdt0mdrch7RITdbAMU2hXHNUrkfvKP8_Uia1XipeD7h9XThz3rHTdml3DTfr_t2VqLv9HjYitpMRsH01L50ASWOkb3GX5tBjiV_sYgWL26-f4q39RRh0pP8EJKFW8KTBy-by6LzDtz7uja7YCVgvLkx8xBbB-zaCb4cTpF"
        frameborder="0" scrolling="no" allowfullscreen
        style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
    </div>
    <div class="landing-faq">
      <h2>FAQ</h2>
      <div class="faq-accordion">
        <label for="faq1" class="faq-question">¿Cómo puedo crear mi biblioteca?</label>
        <input type="checkbox" id="faq1" class="faq-toggle" />
        <div class="faq-answer">
          <p>Crear tu biblioteca es simple. Una vez que abramos al público, podrás registrarte, añadir tus libros y
            personalizar tu colección.</p>
        </div>

        <!--<label for="faq2" class="faq-question">¿Es posible compartir libros físicamente a través de Nexy?</label>
        <input type="checkbox" id="faq2" class="faq-toggle" />
        <div class="faq-answer">
          <p>Sí, estamos implementando una función para que puedas intercambiar o prestar libros físicamente con otros
            usuarios cerca de ti.</p>
        </div>-->

        <label for="faq3" class="faq-question">¿Qué tipo de recomendaciones ofrece Nexy?</label>
        <input type="checkbox" id="faq3" class="faq-toggle" />
        <div class="faq-answer">
          <p>En breve, ofreceremos recomendaciones personalizadas basadas en tu biblioteca y tus gustos, todo gracias a
            nuestra avanzada inteligencia artificial.</p>
        </div>

        <label for="faq4" class="faq-question">¿Puedo participar en la fase de early adopters?</label>
        <input type="checkbox" id="faq4" class="faq-toggle" />
        <div class="faq-answer">
          <p>¡Por supuesto! Si te apasiona la lectura y las nuevas tecnologías, y no te importan los bugs, tu feedback
            será esencial. Regístrate para ser uno de los primeros en experimentar Nexy.</p>
        </div>
      </div>
    </div>
    <footer>
  <h3>¿Quieres contactar con nosotros?</h3>
  <div class="footer-icons">
    <a href="mailto:hello@nexy.ai" target="_blank">hello@nexy.ai</a>
    <a href="https://www.linkedin.com/company/hellonexy" target="_blank"><i class="fab fa-linkedin"></i></a>
    
  </div>
</footer>


  </div>
</template>

<script>
// import NavbarPublic from './landing/NavbarPublic.vue';

export default {
  name: 'LandingPage',
  components: {
    //  NavbarPublic
  },
};
</script>

<style>
.landing-logo {
  margin-top: 10px;
}

.landing-header {
  width: 100%;
  text-align: center;
  margin: 20px 0;
  padding: 80px;
  /* La imagen de fondo y la capa de opacidad se aplican en la misma propiedad background */
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url('./assets/header-background.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 3px;
}


.landing-header h3 {
  font-weight: 300;
  font-size: 24px;
  margin-bottom: 40px;
}

.landing-soon {
  text-align: center;
  margin: 40px 0 80px 0;
}

.landing-soon p {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 50px;
}

.landing-soon h3 {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 20px;
}

.landing-soon-card {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  color: white;
  font-size: 20px;
  text-align: center;

}

.landing-soon-card div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 3px;
  padding: 20px;
  background-color: #262626;
  line-height: 32px;
}

.landing-form {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('./assets/background.jpg');
  height: 800px;
  border-radius: 3px;
}

.landing-form iframe {
  margin: 20px;
}

/* FAQ */
/* Estilos para el acordeón de las FAQ */
.landing-faq {
  margin: 60px;
}

.landing-faq h2 {
  margin: 40px;
  text-align: center;
}

.faq-accordion {
  max-width: 600px;
  margin: 0 auto;
}

.faq-question {
  display: block;
  background-color: transparent;
  font-size: 20px;
  color: white;
  padding: 15px;
  margin: 0;
  font-weight: bold;
  cursor: pointer;
  border: none;
  text-align: center;
  transition: background-color 0.3s ease;

  border-radius: 3px;
}

.faq-question:hover {
  background-color: #00A768;
}

.faq-toggle {
  display: none;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: #262626;
  padding: 0 15px;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
}

/* Usamos el selector de hermano adyacente para activar la respuesta */
.faq-toggle:checked+.faq-answer {
  max-height: 1000px;
  /* Debe ser suficiente para cualquier contenido */
  padding: 15px;
}

footer {
  background-color: 262626;
  text-align: center;
  padding: 20px;
  
}

.footer-icons {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.footer-icons a {
  margin: 0 10px;
  color: white; /* Cambia este color según el estilo de tu sitio */
  font-size: 20px; /* O el tamaño que prefieras */
}

.footer-icons a:hover {
  color: #00A768; /* Cambia este color para el hover según el estilo de tu sitio */
}



/* mobile */ 
@media (max-width: 768px) {
  .landing-logo {
    padding-left: 20px;
  }

  .landing-header {
    padding: 40px;
  }

  .landing-header h1 {
    font-size: 28px;
  }

  .landing-header h3 {
    font-size: 20px;
  }

  .landing-soon {
    margin: 40px 0;
    padding: 0 10px;
  }

  .landing-soon-card {
    flex-direction: column;
  }

  .landing-form {
    height: 700px;
  }

  .landing-faq {
    margin: 40px 0;
  }
}


* { 
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  font-family: 'Roboto', sans-serif;
  background-color: #131313;
  /* Otros estilos globales de tipografía si los necesitas */
}

h1 {
  font-size: 40px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px; 
}

h2 {
  color: white;
}

h3 {
  color: white;
}

p {
  color: white;
}

.links a {
  color: white;
  text-decoration: none;
}

a {
    color: var(--primary-color);
  }

/* CONTENEDORES */ 

.container {
  max-width: 1000px;
  margin: 0 auto;
}

/* BOTONES */

.btn-1 {
  width: 300px;
  margin-bottom: 10px;  
  height: 48px;
  font-size: 18px;
  background-color: #00A768; /* Color de los botones */
  color: white;
  border: none;
  padding: 10px 60px; /* Ajustar según sea necesario */
  cursor: pointer;
  border-radius: 3px;
  text-decoration: none;
}

.btn-1-medium {
  width: 150px;
  height: 48px;
  font-size: 16px;
  background-color: #00A768; /* Color de los botones */
  color: white;
  border: none;
  padding: 10px 20px; /* Ajustar según sea necesario */
  cursor: pointer;
  border-radius: 3px;
}

.btn-2 {
  width: 300px;
  height: 48px;
  font-size: 16px;
  background-color: #262626; 
  color: white;
  border: 1px solid #8B8B8B;
  padding: 10px 20px; 
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 3px;
}

.btn-2-medium {
  width: 150px;
  height: 48px;
  font-size: 16px;
  background-color: #262626; 
  color: white;
  border: 1px solid #8B8B8B;
  padding: 10px 20px; 
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 3px;
}

.btn-3 {
  width: 300px;
  height: 48px;
  font-size: 16px;
  background-color: transparent; 
  color: white;
  border: none;
  padding: 10px 20px; 
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: underline;
}

.option-btn {
  
  font-size: 24px;
  border: none;
  padding: 10px 20px;
  color: white;
  background-color: transparent;
}

/* INPUTS */ 

.input-field {
  width: 300px;
  background-color: #262626;
  border: none;
  color: white; /* Color del texto ingresado */
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 3px;
  height: 48px;
  font-size: 16px;
}

.input-field::placeholder {
  color: white;
  padding: 8px; /* Color del placeholder */
}

.input-field:focus {
  outline: none; /* Elimina el borde del focus */
  border-color: #484848; /* Mantén el borde igual al estado normal si lo prefieres */
} 

.underline {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .primary-button {
    display: block;
    width: 100%; /* Botones en bloque */
    margin: 10px 0; /* Espaciado vertical entre botones */
  }
}
</style>